.errorToast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: #e53e3e;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  border-left: 4px solid #e53e3e;
  animation: slideIn 0.3s ease-out;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.fadeOut {
  opacity: 0;
  transform: translateX(10px);
}

.closeButton {
  background: none;
  border: none;
  color: #718096;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: #4a5568;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

