.editorContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.receiverInput,
.subjectInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

.bodyInput {
  margin-bottom: 10px;
  position: relative; /* Add this to create a positioning context */
}

/* Make toolbar position relative for absolute positioning of dropdowns */
.bodyInput :global(.ql-toolbar) {
  position: relative;
}

.bodyInput :global(.ql-toolbar button) {
  position: relative;
}

.bodyInput :global(.ql-toolbar .ql-picker) {
  position: relative; /* Changed from static to relative */
}

.bodyInput .ql-container {
  height: 200px;
  font-size: 16px;
}

/* Color picker specific styles */
.bodyInput :global(.ql-color-picker),
.bodyInput :global(.ql-background) {
  position: relative !important;
}

.bodyInput :global(.ql-toolbar .ql-color .ql-picker-options),
.bodyInput :global(.ql-toolbar .ql-background .ql-picker-options) {
  top: 100% !important;
  left: 0 !important;
  position: absolute !important;
  z-index: 2000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 3px 5px;
  width: 192px;
}

.bodyInput :global(.ql-toolbar .ql-color .ql-picker-item),
.bodyInput :global(.ql-toolbar .ql-background .ql-picker-item) {
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 1px solid #ccc;
  display: inline-block;
}

/* Tooltip styles */
.bodyInput :global(.ql-toolbar button::after) {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  pointer-events: none;
  z-index: 1000;
}

.bodyInput :global(.ql-toolbar button:hover::after) {
  opacity: 1;
  visibility: visible;
}

/* Custom tooltips for specific buttons */
.bodyInput :global(.ql-bold::after) { content: "Bold" !important; }
.bodyInput :global(.ql-italic::after) { content: "Italic" !important; }
.bodyInput :global(.ql-underline::after) { content: "Underline" !important; }
.bodyInput :global(.ql-strike::after) { content: "Strikethrough" !important; }
.bodyInput :global(.ql-list[value="ordered"]::after) { content: "Numbered List" !important; }
.bodyInput :global(.ql-list[value="bullet"]::after) { content: "Bullet List" !important; }
.bodyInput :global(.ql-link::after) { content: "Insert Link" !important; }
.bodyInput :global(.ql-image::after) { content: "Insert Image" !important; }
.bodyInput :global(.ql-clean::after) { content: "Clear Formatting" !important; }
.bodyInput :global(.ql-color[value="color"]::after) { content: "Text Color" !important; }
.bodyInput :global(.ql-color[value="background"]::after) { content: "Background Color" !important; }

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.scheduleButton,
.updateButton,
.saveButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: #3B82F6;
  color: white;
}

.scheduleButton:hover,
.updateButton:hover,
.saveButton:hover {
  background-color: #2563EB;
}

.closeButton {
  padding: 10px 20px;
  background-color: #e5e7eb;
  color: #374151;
}

.closeButton:hover {
  background-color: #d1d5db;
}

.errorMessage {
  color: #dc2626;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Font classes for the editor content */
.editorContainer :global(.ql-font-arial) {
  font-family: Arial, sans-serif;
}
.editorContainer :global(.ql-font-helvetica) {
  font-family: Helvetica, sans-serif;
}
.editorContainer :global(.ql-font-times-new-roman) {
  font-family: "Times New Roman", serif;
}
.editorContainer :global(.ql-font-courier) {
  font-family: Courier, monospace;
}
.editorContainer :global(.ql-font-verdana) {
  font-family: Verdana, sans-serif;
}
.editorContainer :global(.ql-font-georgia) {
  font-family: Georgia, serif;
}
.editorContainer :global(.ql-font-palatino) {
  font-family: Palatino, serif;
}
.editorContainer :global(.ql-font-garamond) {
  font-family: Garamond, serif;
}
.editorContainer :global(.ql-font-bookman) {
  font-family: Bookman, serif;
}
.editorContainer :global(.ql-font-comic-sans-ms) {
  font-family: "Comic Sans MS", cursive;
}
.editorContainer :global(.ql-font-trebuchet-ms) {
  font-family: "Trebuchet MS", sans-serif;
}
.editorContainer :global(.ql-font-arial-black) {
  font-family: "Arial Black", sans-serif;
}
.editorContainer :global(.ql-font-impact) {
  font-family: Impact, sans-serif;
}

/* Styles for the font dropdown */
.editorContainer :global(.ql-font) {
  width: 150px; /* Adjust width of the dropdown if needed */
}

/* Style the font picker dropdown items */
.editorContainer :global(.ql-font .ql-picker-label::before),
.editorContainer :global(.ql-font .ql-picker-item::before) {
  content: attr(data-value) !important;
}

/* Apply actual fonts to dropdown items */
.editorContainer :global(.ql-picker-item[data-value="arial"]) {
  font-family: Arial, sans-serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="helvetica"]) {
  font-family: Helvetica, sans-serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="times-new-roman"]) {
  font-family: "Times New Roman", serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="courier"]) {
  font-family: Courier, monospace !important;
}
.editorContainer :global(.ql-picker-item[data-value="verdana"]) {
  font-family: Verdana, sans-serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="georgia"]) {
  font-family: Georgia, serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="palatino"]) {
  font-family: Palatino, serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="garamond"]) {
  font-family: Garamond, serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="bookman"]) {
  font-family: Bookman, serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="comic-sans-ms"]) {
  font-family: "Comic Sans MS", cursive !important;
}
.editorContainer :global(.ql-picker-item[data-value="trebuchet-ms"]) {
  font-family: "Trebuchet MS", sans-serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="arial-black"]) {
  font-family: "Arial Black", sans-serif !important;
}
.editorContainer :global(.ql-picker-item[data-value="impact"]) {
  font-family: Impact, sans-serif !important;
}

/* Make the dropdown items more readable */
.editorContainer :global(.ql-picker-item) {
  padding: 5px 10px !important;
  font-size: 14px !important;
}

/* Style for the currently selected font in the dropdown */
.editorContainer :global(.ql-font .ql-picker-label) {
  padding: 0 20px 0 8px !important;
}

/* Add these new styles to position the color picker correctly */
.bodyInput :global(.ql-color-picker) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.bodyInput :global(.ql-toolbar .ql-color .ql-picker-options) {
  padding: 3px 5px;
  width: 152px;
}

.bodyInput :global(.ql-toolbar .ql-color .ql-picker-item) {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
}

/* Add tooltip support for picker labels */
.bodyInput :global(.ql-toolbar .ql-picker.ql-color .ql-picker-label::before),
.bodyInput :global(.ql-toolbar .ql-picker.ql-background .ql-picker-label::before),
.bodyInput :global(.ql-toolbar .ql-picker.ql-align .ql-picker-label::before) {
  position: relative;
}

/* Tooltip styles for picker labels */
.bodyInput :global(.ql-toolbar .ql-picker.ql-color .ql-picker-label::after),
.bodyInput :global(.ql-toolbar .ql-picker.ql-background .ql-picker-label::after),
.bodyInput :global(.ql-toolbar .ql-picker.ql-align .ql-picker-label::after) {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  pointer-events: none;
  z-index: 1000;
}

.bodyInput :global(.ql-toolbar .ql-picker.ql-color .ql-picker-label:hover::after),
.bodyInput :global(.ql-toolbar .ql-picker.ql-background .ql-picker-label:hover::after),
.bodyInput :global(.ql-toolbar .ql-picker.ql-align .ql-picker-label:hover::after) {
  opacity: 1;
  visibility: visible;
}

/* Specific tooltip content for pickers */
.bodyInput :global(.ql-picker.ql-color .ql-picker-label::after) { 
  content: "Text Color" !important; 
}

.bodyInput :global(.ql-picker.ql-background .ql-picker-label::after) { 
  content: "Background Color" !important; 
}

.bodyInput :global(.ql-picker.ql-align .ql-picker-label::after) { 
  content: "Text Alignment" !important; 
}

.editorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.editorHeader h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}
