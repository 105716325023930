.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  max-width: 500px;
  width: 100%;
}

.container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #111827;
}

.container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container input[type="email"],
.container input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.container input[type="file"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #f3f4f6;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.scheduleButton,
.saveButton,
.closeButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.scheduleButton {
  background-color: #0D9488;
  color: white;
}

.scheduleButton:hover {
  background-color: #0F766E;
}

.saveButton {
  background-color: #3B82F6;
  color: white;
}

.saveButton:hover {
  background-color: #2563EB;
}

.closeButton {
  background-color: #e5e7eb;
  color: #374151;
}

.closeButton:hover {
  background-color: #d1d5db;
}

.scheduleButton:disabled,
.saveButton:disabled {
  background-color: #9CA3AF;
  cursor: not-allowed;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}
