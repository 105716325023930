.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  max-width: 500px;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #111827;
  text-align: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.videoPreview {
  width: 100%;
  border-radius: 0.375rem;
  background-color: #f3f4f6;
  margin-bottom: 1.5rem;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.recordButton,
.stopButton,
.uploadButton,
.closeButton {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.recordButton {
  background-color: #10B981;
  color: white;
}

.stopButton {
  background-color: #EF4444;
  color: white;
}

.uploadButton {
  background-color: #3B82F6;
  color: white;
}

.closeButton {
  background-color: #6B7280;
  color: white;
}

.recordButton:hover,
.stopButton:hover,
.uploadButton:hover,
.closeButton:hover {
  opacity: 0.9;
}

.error {
  color: #EF4444;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.progressContainer {
  margin-top: 1rem;
}

.progressBar {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
}

.progressText {
  font-size: 0.875rem;
  color: #4B5563;
  margin-top: 0.5rem;
  display: block;
  text-align: center;
}