.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
  }
  
  .h1 {
    margin: 0 0 20px;
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .message {
    margin-bottom: 20px;
    color: #666;
    line-height: 1.5;
  }
  
  .subMessage {
    margin-bottom: 30px;
    color: #888;
    font-size: 14px;
  }
  
  .link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4285f4;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .link:hover {
    background-color: #3367d6;
  }