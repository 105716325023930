.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1.5rem;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
}

.subtitle {
  color: #4B5563;
}

.composeButton {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  background-color: #0D9488;
  color: white;
  border-radius: 9999px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}

.composeButton:hover {
  background-color: #0F766E;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.lettersContainer {
  margin-top: 2rem;
}

.letterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.error {
  color: #e53e3e;
  margin-top: 1rem;
}

.editorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding-top: 2rem;
}

.editorOverlay > div {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
}

.composeMenu {
  position: fixed;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  min-width: 200px;
}

.composeMenu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.composeMenu button:hover {
  background-color: #f3f4f6;
}

.composeMenu button:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

.composeMenu button svg {
  margin-right: 0.75rem;
  color: #4B5563;
}

.composeMenu button span {
  flex-grow: 1;
}
