:root {
  --primary-color: #4A90E2;
  --secondary-color: #F5A623;
  --background-color: #F8F8F8;
  --text-color: #333333;
}

.body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.h1 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  text-align: center;
}

.p {
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.login_options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%; /* Ensure full width of container */
}

.social_buttons {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 2px;
}
  
.social_button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.2s;
  color: white;
  width: 100%;
}
  
.social_button:hover {
  opacity: 0.9;
}

.social_button.google {
  background-color: #000206;
}

.social_button.facebook {
  background-color: #1877f2;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s;
  box-sizing: border-box;
}

.login_button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.signup_toggle {
  margin-top: 20px;
  font-size: 14px;
  color: var(--text-color);
}

.signup_toggle a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.signup_toggle a:hover {
  text-decoration: underline;
}

.divider {
  text-align: center;
  margin: 24px 0;
  color: #666;
  position: relative;
}
  
.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.subtitle {
  text-align: center;
  color: #666;
  margin: 10px 0 30px;
}