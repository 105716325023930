/* layouts/TopNavigation.module.css */
.navbar {
  background: linear-gradient(to right, #0F766E, #023f3a);
  padding: 1rem;
  color: white;
  width: 100%;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  background-color: #fce7f3;
  border-radius: 9999px;
  padding: 0.75rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoText {
  color: black;
  font-weight: bold;
}

.logo:hover {
  background-color: #fbcfe8;
}

.searchContainer {
  flex: 1;
  max-width: 42rem;
  margin: 0 1rem;
  position: relative;
}

.searchInput {
  width: 100%;
  background-color: rgba(55, 65, 81, 0.5);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  outline: none;
}

.searchInput:focus {
  outline: 2px solid rgba(255, 255, 255, 0.2);
}

.searchIcon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profileButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  background-color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profileButton:hover {
  background-color: #4b5563;
}

.profileContainer {
  position: relative;
  display: inline-block;
}

.profileDropdown {
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  width: 14rem;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  border: 1px solid #e5e7eb;
}

.userInfo {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.userName {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333;
}

.userEmail {
  font-size: 0.875rem;
  color: #666;
}

.menuItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #333;
  transition: background-color 0.2s;
  background: none;
  border: none;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #f3f4f6;
}