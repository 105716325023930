.timerContainer {
    position: relative;
    display: inline-block;
  }
  
  .timerButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .timerButton:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  
  .timerIcon {
    width: 1rem;
    height: 1rem;
  }
  
  .timerDropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 0.5rem);
    width: 18rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    border: 1px solid #e5e7eb;
  }
  
  .timerContent {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .timerSettings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .timerLabel {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .inputGroup {
    display: flex;
    gap: 0.5rem;
  }
  
  .timerInput {
    flex: 1;
    padding: 0.375rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .timerInput:focus {
    outline: none;
    border-color: #3b82f6;
    ring: 2px solid rgba(59, 130, 246, 0.5);
  }
  
  .setButton {
    padding: 0.375rem 0.75rem;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .setButton:hover {
    background-color: #2563eb;
  }
  
  .timerControls {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 0.5rem;
  }
  
  .controlButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
    color: white;
  }
  
  .playButton {
    background-color: #3b82f6;
  }
  
  .playButton:hover {
    background-color: #2563eb;
  }
  
  .restartButton {
    background-color: #10b981;
  }
  
  .restartButton:hover {
    background-color: #059669;
  }
  
  .buttonIcon {
    width: 0.875rem;
    height: 0.875rem;
  }
  
  .errorMessage {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .settingsDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    z-index: 10;
  }

  .settingsDropdown input {
    width: 50px;
    padding: 0.25rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
  }

  .updateButton {
    background-color: #4b5563;
  }

  .updateButton:hover {
    background-color: #374151;
  }

  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    position: relative;
  }

  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
  }

  .modalTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .modalInstructions {
    margin-bottom: 1.5rem;
    color: #666;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .inputGroup label {
    margin-bottom: 0.5rem;
    color: #333;
  }

  .inputGroup input {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 1rem;
  }

  .updateButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #4b5563;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .updateButton:hover {
    background-color: #374151;
  }

  .updateButton:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
