.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.tabContainer {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.tabButton {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}

.tabButton.active {
  border-bottom: 2px solid #4285f4;
  color: #4285f4;
}

.tabContent {
  padding: 20px 0;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.profileInfo {
  display: flex;
  align-items: center;
}

.profileAvatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  background-color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  margin-right: 1rem;
}

.profileAvatar:hover {
  background-color: #4b5563;
}

.profileDetails {
  display: flex;
  flex-direction: column;
}

.profileName {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.25rem;
}

.profilePlan {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.editButton {
  padding: 0.5rem 1rem;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.emailSection {
  margin-top: 2rem;
}

.sectionTitle {
  font-size: 1.5rem;
  margin: 0;
}

.emailInfo {
  display: flex;
  align-items: center;
}

.emailInfo p {
  margin: 0;
  margin-right: 0.5rem;
}

.emailBadge {
  background-color: #e0e0e0;
  color: #333;
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
}

.emailAddress {
  margin-bottom: 1rem;
}

.emailAddress p {
  margin: 0;
}

.emailAddress span {
  font-weight: bold;
}

.primaryEmail {
  margin-bottom: 1rem;
}

.primaryEmail p {
  margin: 0;
}

.primaryEmail span {
  font-weight: bold;
}

.upgradeButton, .securityButton {
  padding: 10px 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.deleteButton {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.tabTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.securitySection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.deleteAccountSection {
  margin-top: 1rem;
}

.deleteAccountHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 1.2rem;
  margin: 0;
}

.deleteAccountTitle {
  font-size: 1.2rem;
  margin: 0;
  color: #dc3545;
}

.securityButton,
.deleteButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.securityButton {
  background-color: #4285f4;
  color: white;
}

.securityButton:hover {
  background-color: #3367d6;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
}

.deleteButton:hover {
  background-color: #c82333;
}

.warningText {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.separator {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .securityButtonsContainer {
    flex-direction: row;
  }

  .securityButton {
    width: auto;
  }
}