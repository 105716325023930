.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.message {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancelButton {
  background-color: #e2e8f0;
  color: #4a5568;
}

.cancelButton:hover {
  background-color: #cbd5e0;
}

.confirmButton {
  background-color: #e53e3e;
  color: white;
}

.confirmButton:hover {
  background-color: #c53030;
}