.letterTile {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  height: 180px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.letterTile:hover {
  background: linear-gradient(to bottom right, #ffffff, #f8fafc);
}

.icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  color: #0D9488;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.subject {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #1e293b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipient {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  border: none;
  border-top: 1px solid #e2e8f0;
  margin: 0.5rem 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
}

.date {
  color: #64748b;
}

.state {
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
}

.stateDraft {
  background-color: #f1f5f9;
  color: #64748b;
}

.stateScheduled {
  background-color: #ecfdf5;
  color: #059669;
}

.stateSent {
  background-color: #eff6ff;
  color: #3b82f6;
}

.stateDefault {
  background-color: #f1f5f9;
  color: #64748b;
}

.letterCard {
  width: 100%;
  height: 16rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background: white;
  transition: all 0.2s;
  cursor: pointer;
  position: relative; /* Add this to ensure proper positioning of absolute elements */
}

.letterCard:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-color: #bfdbfe;
  background: linear-gradient(to bottom right, #ffffff, #dde5ee);
}

.letterContent {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.iconContainer {
  margin-bottom: 1rem;
}

.mailIconWrapper {
  width: 3rem;
  height: 3rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: #0D9488;
}

.letterDetails {
  margin-bottom: auto;
}

.letterTitle {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #1e293b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.letterRecipient {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.letterPreview {
  font-size: 0.875rem;
  color: #4b5563;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.letterFooter {
  padding-top: 1rem;
  margin-top: auto;
  border-top: 1px solid #e5e7eb;
  font-size: 0.75rem;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.calendarIcon {
  width: 0.75rem;
  height: 0.75rem;
}

.statusBadge {
  text-transform: capitalize;
  padding: 0.25rem 0.50rem;
  border-radius: 9999px;
}

.stateDraft {
  background-color: #f1f5f9;
  color: #64748b;
}

.stateScheduled {
  background-color: #97f4c9;
  color: #03543a;
}

.stateSent {
  background-color: #eff6ff;
  color: #3b82f6;
}
.videoLetterCard {
  background: linear-gradient(to bottom right, #f0f9ff, #c3deee);
}

.videoIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: #0284c7;
}

.thumbnailContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.kebabMenu {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.kebabButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 50%;
}

.kebabButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #374151;
}

.menuOptions {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 20;
  min-width: 150px;
  overflow: hidden;
}

.menuOptions button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #374151;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.menuOptions button:hover {
  background-color: #f3f4f6;
}

.menuOptions button:active {
  background-color: #e5e7eb;
}
