.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.form_container {
  width: 100%;
  padding: 0 2px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.subtitle {
  text-align: center;
  color: #666;
  margin: 10px 0 30px;
}

.social_buttons {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 2px;
}

.social_button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.2s;
  color: white;
  width: 100%;
}

.social_button:hover {
  opacity: 0.9;
}

.social_button.google {
  background-color: #000206;
}

.social_button.facebook {
  background-color: #1877f2;
}

.divider {
  text-align: center;
  margin: 24px 0;
  color: #666;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.input_group {
  margin-bottom: 10px;
}

.input_group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.name_fields {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  margin-left: 2px;
}

.name_fields .input_group {
  flex: 1;
  min-width: 0; /* Prevents input from overflowing */
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s;
  box-sizing: border-box;
}

.input_multi {
  width: 90%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.input:focus {
  outline: none;
  border-color: #4285f4;
}

.optional {
  font-size: 12px;
  color: #666;
  margin-left: 8px;
}

.phone_input {
  display: flex;
  gap: 8px;
}

.country_code {
  width: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
}

.password_input {
  position: relative;
}

.toggle_password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0;
}

.password_requirement {
  color: #dc3545;
  font-size: 12px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.submit_button {
  width: 100%;
  padding: 12px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit_button:hover {
  background-color: #3367d6;
}

.submit_button:disabled {
  background-color: #b8b8b8;
  cursor: not-allowed;
}

.signin_link {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #666;
}

.signin_link a {
  color: #4285f4;
  text-decoration: none;
  font-weight: 500;
}

.signin_link a:hover {
  text-decoration: underline;
}

.error_message {
  background-color: #fde8e8;
  color: #dc3545;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .card {
    padding: 24px;
  }

  .name_fields {
    flex-direction: column; /* Stack vertically on small screens */
    gap: 16px;
  }

  .social_buttons {
    grid-template-columns: 1fr;
  }
}

.password_requirements {
  list-style-type: none;
  padding-left: 0;
  margin-top: 8px;
  font-size: 12px;
  color: #dc3545;
}

.password_requirement_item {
  margin-bottom: 4px;
}

.password_mismatch {
  color: #dc3545;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.submit_button:disabled {
  background-color: #b8b8b8;
  cursor: not-allowed;
}