.container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  max-width: 500px;
  width: 100%;
}

.container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #111827;
}

.container p {
  color: #4B5563;
  margin-bottom: 1rem;
}

.videoPlayer {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.closeButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: #e5e7eb;
  color: #374151;
}

.closeButton:hover {
  background-color: #d1d5db;
}

.error {
  color: #dc2626;
  font-weight: 500;
  margin-bottom: 1rem;
}
